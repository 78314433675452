.scale-cero .mini-map-container {
  transform: scale(0);
  z-index: 0;
}

.map-open .mini-map-container {
  transform: scale(1);
  z-index: 1020;
}

.mini-map-container {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 948px;
  max-width: calc(100% - 160px);
  max-height: calc(100% - 160px);
  background-size: cover;
  transition: all 0.5s;
}

.map-container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1020;
}

.mini-map {
  z-index: 1020;
}

.map-mobile-container { 
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1000;
}

@media screen and (orientation: landscape) {
  .verticalPortrait {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: rotate(90deg);
    position: absolute;
    z-index: -1;
  }

  .minimap-image-vertical {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    z-index: -1;
  }
}

@media screen and (orientation: portrait) {
  .verticalPortrait {
    transform: rotate(90deg);
  }
}

.map-hotspot-container {
  position: absolute;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.map-hotspot-container.active {
  width: 56px;
  height: 56px;
  animation: none;
  -webkit-animation: none;
}

.map-hotspot-container .map-hotspot {
  width: 40px;
  height: 40px;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  animation: pulsateBg 1s ease-out;
  -webkit-animation: pulsateBg 1s ease-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  z-index: -1;
}

.map-hotspot-container.active .map-hotspot {
  width: 56px;
  height: 56px;
  animation: none;
  -webkit-animation: none;
}

.map-hotspot-container .circle {
  display: none;
  position: absolute;
  z-index: 1001;
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(2.5, 2.5);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.7, 0.7);
  }
  50% {
    -webkit-transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(0.7, 0.7);
  }
}

@-webkit-keyframes pulsateBg {
  0% {
    -webkit-transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.1, 1.1);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}

@keyframes pulsateBg {
  0% {
    -webkit-transform: scale(0.95, 0.95);
  }
  50% {
    -webkit-transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(0.95, 0.95);
  }
}

.map-hotspot-container.active .circle {
  display: block;
  width: 22px;
  height: 22px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  -webkit-animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

.map-hotspot-container .ringring {
  border: 3px solid #fff;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  height: 22px;
  width: 22px;
  position: absolute;
  z-index: 1001;
}
.map-hotspot-container.active .ringring {
  display: none;
}

@media (max-width: 768px) {
  .map-hotspot-container {
    width: 25px;
    height: 25px;
  }

  .map-hotspot-container.active {
    width: 35px;
    height: 35px;
  }

  .map-hotspot-container .map-hotspot {
    width: 25px;
    height: 25px;
  }

  .map-hotspot-container.active .map-hotspot {
    width: 35px;
    height: 35px;
  }

  .map-hotspot-container.active .circle {
    width: 15px;
    height: 15px;
  }

  .map-hotspot-container .ringring {
    border-radius: 17px;
    -webkit-border-radius: 17px;
    height: 17px;
    width: 17px;
  }
}

/* #living-hotspot.map-hotspot,
#sala-hotspot.map-hotspot {
  top: 193px;
  left: 63px;
}

#dining-hotspot.map-hotspot,
#comedor-hotspot.map-hotspot {
  top: 95px;
  left: 110px;
}

#kitchen-hotspot.map-hotspot,
#cocina-hotspot.map-hotspot {
  top: 17px;
  left: 58px;
}

#laundryroom-hotspot.map-hotspot,
#lavanderia-hotspot.map-hotspot {
  top: -11px;
  left: 62px;
}

#bedroom1-hotspot.map-hotspot,
#recamara1-hotspot.map-hotspot {
  top: 140px;
  left: 117px;
}

#bedroom2-hotspot.map-hotspot,
#recamara2-hotspot.map-hotspot {
  top: -14px;
  left: 114px;
}

#bathroom-hotspot.map-hotspot,
#baño-hotspot.map-hotspot {
  top: 35px;
  left: 140px;
}

#laundryroom-hotspot.map-hotspot,
#lavandería-hotspot.map-hotspot {
  top: -82px;
  left: 55px;
}

.map-mobile-container #living-hotspot.map-hotspot,
.map-mobile-container #sala-hotspot.map-hotspot {
  top: 110px;
  left: -37px;
}

.map-mobile-container #dining-hotspot.map-hotspot,
.map-mobile-container #comedor-hotspot.map-hotspot {
  top: 20px;
  left: 13px;
}

.map-mobile-container #kitchen-hotspot.map-hotspot,
.map-mobile-container #cocina-hotspot.map-hotspot {
  top: -63px;
  left: -43px;
}

.map-mobile-container #laundryroom-hotspot.map-hotspot,
.map-mobile-container #lavanderia-hotspot.map-hotspot {
  top: -112px;
  left: -35px;
}

.map-mobile-container #bedroom1-hotspot.map-hotspot,
.map-mobile-container #recamara1-hotspot.map-hotspot {
  top: -74px;
  left: 22px;
}

.map-mobile-container #bedroom2-hotspot.map-hotspot,
.map-mobile-container #recamara2-hotspot.map-hotspot {
  top: 65px;
  left: 22px;
}

.map-mobile-container #bathroom-hotspot.map-hotspot,
.map-mobile-container #baño-hotspot.map-hotspot {
  top: -35px;
  left: 40px;
}

.map-mobile-container #laundryroom-hotspot.map-hotspot,
.map-mobile-container #lavandería-hotspot.map-hotspot {
  top: -150px;
  left: -45px;
} */

.mobile-minimap-container {
  position: relative;
  width: 100%;
  margin: 20px 0;
}
