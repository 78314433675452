.scale-cero .floors-menu {
  transform: scale(0);
}

.map-open .floors-menu {
  transform: scale(1);
}

.floors-menu {
  position: absolute;
  margin: auto;
  top: calc(50% + 496px / 2);
  left: 0;
  right: 0;
  width: 110px;
  height: 30px;
  overflow: hidden;
  background-size: cover;
  border-radius: 21.6px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1020;
  transition: all 0.5s;
}

.floors-menu-mobile {
  background: rgb(221, 217, 213);
  border-radius: 24px;
  z-index: 1000;
  padding: 2px;
  right: 0;
  left: 0;
  margin: 30px auto 20px auto;
  width: 110px;
}

.oval {
  width: 26px;
  height: 25.7px;
  background-color: #ffffff;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: center;
  color: #6c6c6c;
  cursor: pointer;
}

.current {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #6c6c6c;
}

.floors-menu-mobile .current {
  color: #ffffff;
}

.oval.current {
  border: solid 0.9px #ed6b6a;
}

.ladder-icon {
  width: 21.7px;
  height: 21.6px;
  margin-bottom: 3px;
  margin-left: 5px;
}

.grayed {
  background-color: #ededed;
  cursor: default;
}
