@import url(https://fonts.googleapis.com/css?family=Roboto);
body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
  font-family: serif;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.77);
  font-family: 'Roboto', sans-serif;
  background: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  position: fixed;
}

#root .blur {
  filter: blur(25px);
  -webkit-filter: blur(25px);
}

.blur {
  filter: blur(25px);
  -webkit-filter: blur(25px);
}

.display-none {
  display: none !important;
  width: 0px;
  transition: all 0.4s ease-in-out;
}

.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1002;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: 1s opacity; }
  .loader.white-background {
    background-color: white; }
  .loader.scene {
    background-color: transparent; }
  .loader.loader.none {
    opacity: 0; }
    .loader.loader.none .loader-image-container {
      opacity: 0; }
  .loader.show {
    opacity: 1; }
  .loader .loader-image-container {
    background-color: white;
    height: 100px;
    width: 100px;
    border-radius: 50%; }
  .loader.display-none {
    display: 'none'; }

.three-sixty-blur {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: 1s opacity; }
  .three-sixty-blur.none {
    opacity: 0; }

.icon {
  position: fixed;
  min-width: 120px;
  min-height: 48px;
  width: 152px;
  height: 60px;
  top: 16px;
  left: 16px;
  object-fit: contain;
  z-index: 1000;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: left;
  align-items: center;
  /* 
  &:hover {
    cursor: pointer;
  } */ }
  .icon img {
    object-fit: contain; }
  .icon.hide {
    opacity: 0; }

@media (max-width: 767px) {
  .icon {
    top: auto;
    bottom: 3%;
    right: 0;
    left: 0;
    margin: 0 auto;
    justify-content: center;
    /*  &:hover {
      cursor: pointer;
    } */ }
    .icon img {
      object-fit: contain; }
    .icon.hide {
      opacity: 0; } }

.sub-menu .image-container-card {
  width: 270px;
  height: 144px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1021;
}

.sub-menu .image-container-card .image_title_card {
  background: #252525;
  width: 100%;
  height: 26px;
  border-radius: 3.5px;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.gradient.element1 {
  width: 100%;
  height: 100%;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #ededed;
  background: linear-gradient(to right, #ededed 8%, #f4f4f4 38%, #ededed 54%);
  background-size: 100%;
}

.gradient.element3 {
  width: 142px;
  height: 11px;
  margin-left: 15px;
  background-color: #464646;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: linear-gradient(to right, #464646 8%, #545454 38%, #464646 54%);
  background-size: 100%;
}

.mobile-submenu .image-container-card {
  width: 100%;
  height: 144px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1021;
}

.mobile-submenu .image-container-card .image_title_card {
  background: #252525;
  width: 100%;
  height: 26px;
  border-radius: 3.5px;
}
.nav-container {
  position: absolute;
  background: rgba(255, 255, 255, 0.95);
  margin: auto;
  top: 0;
  bottom: 0;
  right: 16px;
  width: 48px;
  height: 308px;
  overflow: hidden;
  border-radius: 24px;
  z-index: 1000;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  -webkit-transform-origin: left top;
          transform-origin: left top; }
  .nav-container.expanded {
    position: absolute;
    height: 100vh;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    width: 344px;
    right: 0;
    overflow: visible;
    border-radius: 0;
    background: rgba(255, 255, 255, 0.95);
    z-index: 1000; }
  .nav-container .nav-container.expanded .main-menu {
    background: transparent;
    margin-right: 16px; }
  .nav-container.hide {
    opacity: 0; }

.main-menu {
  position: absolute;
  background: transparent;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 16px;
  width: 48px;
  height: 308px;
  overflow: hidden;
  border-radius: 24px;
  z-index: 1001; }
  .main-menu.expanded {
    position: absolute;
    height: 100vh;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    width: 344px;
    right: 0;
    overflow: visible;
    border-radius: 0;
    background: transparent;
    z-index: 1001; }
    .main-menu.expanded .options-container .nav-icon-container {
      margin: 0px 18px; }
  .main-menu .options-container {
    width: 100%; }
    .main-menu .options-container .nav-icon-container {
      margin: 10px auto; }
    .main-menu .options-container .menu-action.beacon {
      width: 40px;
      height: 40px;
      opacity: 0.58;
      border-radius: 50%;
      border: solid 1px var(--coral);
      background-color: rgba(241, 118, 121, 0.37); }

.sub-menu-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
  top: 20px;
  bottom: 0;
  left: 75px;
  overflow: hidden;
  max-height: 0px;
  z-index: 1000;
  height: auto;
  width: 270px;
  max-height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  margin-right: 64px; }
  .sub-menu-container.closed {
    display: none; }
  .sub-menu-container.unactive {
    opacity: 0; }
  .sub-menu-container.active, .sub-menu-container.views.active {
    transition: all 0.2s linear; }
  .sub-menu-container .nav-icon-container {
    margin: 14px 5px;
    padding: 11px; }
  .sub-menu-container .menu-properties-container {
    height: 100vh;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100); }
  .sub-menu-container .menu-properties-container .title {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ed6b6a;
    margin-top: 10px;
    margin-bottom: 10px; }

.sub-menu {
  font-family: Roboto;
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow-y: scroll; }
  .sub-menu::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: transparent; }
  .sub-menu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: transparent; }
  .sub-menu::-webkit-scrollbar {
    width: 0px;
    scrollbar-width: 0px;
    background-color: transparent; }
  .sub-menu::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: transparent; }
  .sub-menu::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: transparent; }
  .sub-menu .shopping-car-item {
    font-family: Roboto;
    width: 248px;
    height: 240px;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(138, 138, 138, 0.39), 0 1px 2px 0 rgba(138, 138, 138, 0.5);
    background-color: #ffffff;
    margin-bottom: 10px; }
    .sub-menu .shopping-car-item:hover {
      cursor: pointer;
      box-shadow: 0 2px 10px 0 #7d7d7d; }
    .sub-menu .shopping-car-item .item .name,
    .sub-menu .shopping-car-item .item .cart-image-container {
      padding-top: 12px;
      padding-left: 10px;
      padding-right: 10px; }
    .sub-menu .shopping-car-item .item .cart-image-container {
      padding-bottom: 13px; }
      .sub-menu .shopping-car-item .item .cart-image-container .cart-image {
        width: 188px;
        height: 121px;
        border-radius: 6px; }
        .sub-menu .shopping-car-item .item .cart-image-container .cart-image img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 3.5px; }
      .sub-menu .shopping-car-item .item .cart-image-container .colors {
        height: 110px;
        overflow: hidden; }
    .sub-menu .shopping-car-item .item .retailer {
      padding-left: 10px; }
    .sub-menu .shopping-car-item .item .name,
    .sub-menu .shopping-car-item .item .retailer,
    .sub-menu .shopping-car-item .item .cart-image-container {
      width: 100%; }
    .sub-menu .shopping-car-item .item .name .furniture-name {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #000000; }
    .sub-menu .shopping-car-item .item .retailer {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: 1.14;
      letter-spacing: normal;
      color: #6c6c6c; }
    .sub-menu .shopping-car-item .footer {
      height: 43px;
      width: 100%;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      background-color: #252525;
      color: #fafafa;
      font-size: 12px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      color: #ffffff; }
      .sub-menu .shopping-car-item .footer .currency {
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #ffffff; }
      .sub-menu .shopping-car-item .footer .price {
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        color: #ffffff; }
      .sub-menu .shopping-car-item .footer .footer-info {
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #ffffff; }

.image-container {
  margin: 15px 0px;
  height: 144px;
  position: relative;
  overflow: hidden; }
  .image-container .image.loading {
    opacity: 0; }
  .image-container .image .image_title {
    background: #252525;
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    min-height: 26px;
    border-radius: 3.5px; }
    .image-container .image .image_title::first-letter {
      text-transform: uppercase; }
    .image-container .image .image_title span {
      display: block;
      font-family: Roboto;
      color: #fff;
      text-decoration: none;
      font-size: 14px;
      padding: 5px 8px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.14;
      letter-spacing: normal;
      word-wrap: break-word; }
  .image-container .image.loaded {
    transition: all 0.5s linear; }
  .image-container:first-child {
    margin-top: 0px; }
  .image-container:hover {
    cursor: pointer;
    box-shadow: 0 2px 10px 0 #7d7d7d;
    border-radius: 7px; }
  .image-container .image {
    height: 100%; }
    .image-container .image .image_title {
      width: 100%; }
  .image-container .image span img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 3.5px; }
  .image-container.active .image span img {
    border: solid 3px #ed6b6a; }
  .image-container.active .image .image_title {
    border: solid 3px #ed6b6a;
    border-top: none; }

.personalize-button-container {
  width: 100%;
  height: 40px;
  margin: 0 auto;
  border-radius: 20.5px;
  box-shadow: 0 2px 4px 0 rgba(138, 138, 138, 0.5);
  color: white;
  background-color: #ed6b6a;
  display: table;
  margin-bottom: 30px; }
  .personalize-button-container.survey-completed {
    width: 150px; }

.styles-icon:hover,
.views-icon:hover,
.personalize-icon:hover,
.expand-icon:hover,
.personalize-button-container:hover,
.mini-map-icon:hover,
.furniture-icon:hover,
.change-room-icon:hover,
.day-night-icon:hover,
.finishes-icon:hover {
  cursor: pointer; }

.styles-icon {
  width: 24px;
  height: 24px;
  background: url("https://athum.com/360/Icon_styles_intermediate-gray.svg");
  background-size: 24px 24px; }
  .styles-icon.active, .styles-icon:hover {
    background: url("https://athum.com/360/icon-styles-intermediate-active.svg"); }

.mini-map-icon {
  width: 24px;
  height: 24px;
  background: url("https://athum.com/360/icon-layout-gray.svg");
  background-size: 24px 24px; }
  .mini-map-icon.active, .mini-map-icon:hover {
    background: url("https://athum.com/360/icon-layout-active.svg"); }

.views-icon {
  width: 24px;
  height: 24px;
  background: url("https://athum.com/360/Icon_view_gray2.svg");
  background-size: 24px 24px; }
  .views-icon.active, .views-icon:hover {
    background: url("https://athum.com/360/Icon_view_coral.svg"); }

.personalyze-icon {
  width: 24px;
  height: 24px;
  background: url("https://athum.com/360/Icon_personalize_gray.svg"); }
  .personalyze-icon.active, .personalyze-icon:hover {
    background: url("https://athum.com/360/Icon_personalize_coral.svg"); }

.furniture-icon {
  width: 24px;
  height: 24px;
  background: url("https://athum.com/360/icon-cart.svg");
  background-size: 24px 24px; }
  .furniture-icon.active, .furniture-icon:hover {
    background: url("https://athum.com/360/icon-cart-active.svg"); }

.change-room-icon {
  width: 24px;
  height: 24px;
  background: url("https://athum.com/360/icon-space-layout.svg");
  background-size: 24px 24px; }
  .change-room-icon.active, .change-room-icon:hover {
    background: url("https://athum.com/360/icon-space-layout-active.svg"); }

.day-night-icon {
  width: 24px;
  height: 24px;
  background: url("https://athum.com/360/icon-night.svg");
  background-size: 24px 24px; }
  .day-night-icon.active, .day-night-icon:hover {
    background: url("https://athum.com/360/icon-night-active.svg"); }

.finishes-icon {
  width: 24px;
  height: 24px;
  background: url("https://athum.com/360/icon-finishes.svg");
  background-size: 24px 24px; }
  .finishes-icon.active, .finishes-icon:hover {
    background: url("https://athum.com/360/icon-finishes-active.svg"); }

.map-icon {
  width: 24px;
  height: 24px;
  background: url("https://athum.com/360/Icon_layout_gray.svg");
  background-size: 24px 24px; }
  .map-icon.active, .map-icon:hover {
    background: url("https://athum.com/360/Icon_layout_coral.svg"); }

.room-change-icon {
  width: 24px;
  height: 24px;
  background: url("https://athum.com/360/icon-space-layout.svg");
  background-size: 24px 24px; }
  .room-change-icon.active, .room-change-icon:hover {
    background: url("https://athum.com/360/icon-space-layout-active.svg"); }

.cart-icon {
  width: 24px;
  height: 24px;
  background: url("https://athum.com/360/icon-cart.svg");
  background-size: 24px 24px; }
  .cart-icon.active, .cart-icon:hover {
    background: url("https://athum.com/360/icon-cart-active.svg"); }

.night-icon {
  width: 24px;
  height: 24px;
  background: url("https://athum.com/360/icon-night.svg");
  background-size: 24px 24px; }
  .night-icon.active, .night-icon:hover {
    background: url("https://athum.com/360/icon-night-active.svg"); }

.finishes-icon {
  width: 24px;
  height: 24px;
  background: url("https://athum.com/360/icon-finishes.svg");
  background-size: 24px 24px; }
  .finishes-icon.active, .finishes-icon:hover {
    background: url("https://athum.com/360/icon-finishes-active.svg"); }

.minimap-mobile-icon {
  width: 24px;
  height: 24px;
  background: url("https://athum.com/360/icon-layout-gray.svg");
  background-size: 24px 24px; }
  .minimap-mobile-icon.active, .minimap-mobile-icon:hover {
    background: url("https://athum.com/360/icon-layout-active.svg"); }

.mobile-menu-container {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  box-shadow: 0 1px 2px 0 rgba(138, 138, 138, 0.5);
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 1003; }
  .mobile-menu-container .close-menubutton {
    position: fixed;
    width: 24px;
    height: 24px;
    object-fit: contain;
    z-index: 1003;
    top: 20px;
    right: 15px; }
  .mobile-menu-container .mobile-menu {
    position: fixed;
    z-index: 1003; }
    .mobile-menu-container .mobile-menu .nav-mobile-icon-container {
      margin: 15px; }
    @media screen and (orientation: portrait) {
      .mobile-menu-container .mobile-menu {
        flex-direction: column;
        width: 24px;
        right: 15px;
        top: 50px; }
        .mobile-menu-container .mobile-menu .nav-mobile-icon-container {
          margin: 15px 0; } }
    @media screen and (orientation: landscape) {
      .mobile-menu-container .mobile-menu {
        flex-direction: row;
        left: 15px;
        top: 20px; }
        .mobile-menu-container .mobile-menu .nav-mobile-icon-container {
          margin: 0 15px; } }
  .mobile-menu-container .sub-mobile-menu-container {
    position: absolute;
    background: transparent;
    z-index: 1000;
    overflow: auto; }
    .mobile-menu-container .sub-mobile-menu-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: transparent; }
    .mobile-menu-container .sub-mobile-menu-container::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: transparent; }
    .mobile-menu-container .sub-mobile-menu-container::-webkit-scrollbar {
      width: 0px;
      scrollbar-width: 0px;
      background-color: transparent; }
    .mobile-menu-container .sub-mobile-menu-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: transparent; }
    .mobile-menu-container .sub-mobile-menu-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: transparent; }
    .mobile-menu-container .sub-mobile-menu-container .nav-icon-container {
      margin: 0px 10px; }
    .mobile-menu-container .sub-mobile-menu-container.views-mobile {
      max-height: 100%;
      top: 50%;
      width: 100%;
      transition: all 0.3s ease; }
      @media screen and (orientation: portrait) {
        .mobile-menu-container .sub-mobile-menu-container.views-mobile {
          height: 100%; } }
      @media screen and (orientation: landscape) {
        .mobile-menu-container .sub-mobile-menu-container.views-mobile {
          height: 50%; } }
    .mobile-menu-container .sub-mobile-menu-container .mobile-submenu .image-container {
      border-radius: 6px;
      position: relative;
      margin: 15px 0px;
      overflow: hidden;
      height: 144px;
      width: 100%; }
      @media screen and (orientation: landscape) {
        .mobile-menu-container .sub-mobile-menu-container .mobile-submenu .image-container {
          width: 48%;
          margin: 15px 1%; } }
    @media screen and (orientation: landscape) {
      .mobile-menu-container .sub-mobile-menu-container .mobile-submenu {
        padding-top: 15px; } }
    .mobile-menu-container .sub-mobile-menu-container .mobile-submenu.views-mobile-menu, .mobile-menu-container .sub-mobile-menu-container .mobile-submenu.finish-mobile-menu {
      z-index: 1003;
      width: 100%;
      margin-bottom: 20px; }
      .mobile-menu-container .sub-mobile-menu-container .mobile-submenu.views-mobile-menu div img, .mobile-menu-container .sub-mobile-menu-container .mobile-submenu.finish-mobile-menu div img {
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
        object-fit: cover; }
      @media (max-width: 812px) {
        .mobile-menu-container .sub-mobile-menu-container .mobile-submenu.views-mobile-menu, .mobile-menu-container .sub-mobile-menu-container .mobile-submenu.finish-mobile-menu {
          width: 95%; } }
      .mobile-menu-container .sub-mobile-menu-container .mobile-submenu.views-mobile-menu .image-container .image, .mobile-menu-container .sub-mobile-menu-container .mobile-submenu.finish-mobile-menu .image-container .image {
        height: 100%;
        border-radius: 6px; }
      @media screen and (orientation: portrait) {
        .mobile-menu-container .sub-mobile-menu-container .mobile-submenu.views-mobile-menu, .mobile-menu-container .sub-mobile-menu-container .mobile-submenu.finish-mobile-menu {
          flex-direction: column; } }
      @media screen and (orientation: landscape) {
        .mobile-menu-container .sub-mobile-menu-container .mobile-submenu.views-mobile-menu, .mobile-menu-container .sub-mobile-menu-container .mobile-submenu.finish-mobile-menu {
          flex-direction: row;
          flex-wrap: wrap; } }
    .mobile-menu-container .sub-mobile-menu-container .mobile-personalize-container {
      width: 100%;
      flex-wrap: wrap;
      margin: 15px 0; }
      .mobile-menu-container .sub-mobile-menu-container .mobile-personalize-container .mobile-personalize-intro {
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: #6c6c6c; }
      .mobile-menu-container .sub-mobile-menu-container .mobile-personalize-container .mobile-personalize-button {
        height: 40px;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        background-color: #ed6b6a;
        border-radius: 20.5px;
        box-shadow: 0 2px 4px 0 rgba(138, 138, 138, 0.5);
        padding: 8px;
        border: none; }
        .mobile-menu-container .sub-mobile-menu-container .mobile-personalize-container .mobile-personalize-button .mobile-personalize-button-icon {
          margin-right: 5px;
          width: 24px !important;
          height: 24px !important; }
      @media screen and (orientation: landscape) {
        .mobile-menu-container .sub-mobile-menu-container .mobile-personalize-container {
          margin: 15px 0;
          justify-content: flex-start; }
          .mobile-menu-container .sub-mobile-menu-container .mobile-personalize-container .mobile-personalize-intro {
            width: 45%;
            margin: 10px; }
          .mobile-menu-container .sub-mobile-menu-container .mobile-personalize-container .mobile-personalize-button {
            width: 45%;
            margin: 10px; } }
      @media screen and (orientation: portrait) {
        .mobile-menu-container .sub-mobile-menu-container .mobile-personalize-container {
          margin: 15px 0;
          justify-content: center; }
          .mobile-menu-container .sub-mobile-menu-container .mobile-personalize-container .mobile-personalize-intro {
            width: 95%;
            margin-bottom: 15px; }
          .mobile-menu-container .sub-mobile-menu-container .mobile-personalize-container .mobile-personalize-button {
            width: 95%; } }
    @media screen and (orientation: portrait) {
      .mobile-menu-container .sub-mobile-menu-container {
        width: calc(100% - 69px);
        height: 100%;
        left: 15px;
        padding-top: 20px; } }
    @media screen and (orientation: landscape) {
      .mobile-menu-container .sub-mobile-menu-container {
        width: calc(100% - 30px);
        height: calc(100% - 39px);
        left: 15px;
        top: calc(59px); } }

.style-item {
  margin: 10px 10px;
  width: 186px;
  height: 42px;
  border-radius: 21px;
  background-color: #ffffff; }
  .style-item .button {
    width: 100%;
    height: 100%;
    opacity: 0.8;
    border-radius: 21px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23); }

.mobile-submenu-title-container {
  width: 100%; }

.mobile-submenu-title {
  width: 100%;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ed6b6a;
  padding-left: 10px;
  padding-bottom: 10px; }
  @media screen and (orientation: portrait) {
    .mobile-submenu-title {
      padding-top: 5px; } }

.mobile-submenu-floorplan {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #6c6c6c;
  padding-left: 10px;
  padding-bottom: 10px; }

.mobile-menu-button {
  position: fixed;
  width: 48px;
  height: 48px;
  box-shadow: 0 1px 2px 0 rgba(138, 138, 138, 0.5);
  background-color: #ed6b6a;
  border: none;
  z-index: 1003;
  bottom: 3%;
  right: 15px;
  border-radius: 50%;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center; }

.no-touch .scrollable.hover {
  overflow-y: hidden; }

.no-touch .scrollable.hover:hover {
  overflow-y: auto;
  overflow: visible; }

/*
.sub-mobile-menu-container.views-mobile .carousel {
  width: 100%;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
}

.sub-mobile-menu-container.views-mobile .carousel .carousel-item {
  margin-top: 9px;
}

.sub-mobile-menu-container.views-mobile
  .carousel
  .carousel-item
  > [class*='col-'] {
  margin-left: 5px;
  margin-right: 6px;
}
.sub-mobile-menu-container.views-mobile .carousel .carousel-item .col-4 {
  width: 30.55555%;
  padding-left: 8px;
  padding-right: 0;
}

.sub-mobile-menu-container.views-mobile .carousel .carousel-inner {
  height: 120px;
}
*/

.scale-cero .floors-menu {
  -webkit-transform: scale(0);
          transform: scale(0);
}

.map-open .floors-menu {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.floors-menu {
  position: absolute;
  margin: auto;
  top: calc(50% + 496px / 2);
  left: 0;
  right: 0;
  width: 110px;
  height: 30px;
  overflow: hidden;
  background-size: cover;
  border-radius: 21.6px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1020;
  transition: all 0.5s;
}

.floors-menu-mobile {
  background: rgb(221, 217, 213);
  border-radius: 24px;
  z-index: 1000;
  padding: 2px;
  right: 0;
  left: 0;
  margin: 30px auto 20px auto;
  width: 110px;
}

.oval {
  width: 26px;
  height: 25.7px;
  background-color: #ffffff;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: center;
  color: #6c6c6c;
  cursor: pointer;
}

.current {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #6c6c6c;
}

.floors-menu-mobile .current {
  color: #ffffff;
}

.oval.current {
  border: solid 0.9px #ed6b6a;
}

.ladder-icon {
  width: 21.7px;
  height: 21.6px;
  margin-bottom: 3px;
  margin-left: 5px;
}

.grayed {
  background-color: #ededed;
  cursor: default;
}

@charset "UTF-8";
.scale-cero .mini-map-container {
  -webkit-transform: scale(0);
          transform: scale(0);
  z-index: 0; }

.map-open .mini-map-container {
  -webkit-transform: scale(1);
          transform: scale(1);
  z-index: 1020; }

.mini-map-container {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 948px;
  max-width: calc(100% - 160px);
  max-height: calc(100% - 160px);
  background-size: cover;
  transition: all 0.5s; }

.map-container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1020; }

.mini-map {
  z-index: 1020; }

.map-mobile-container {
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1000; }

@media screen and (orientation: landscape) {
  .verticalPortrait {
    width: 100%;
    height: 100%;
    object-fit: contain;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    position: absolute;
    z-index: -1; }
  .minimap-image-vertical {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    z-index: -1; } }

@media screen and (orientation: portrait) {
  .verticalPortrait {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); } }

.map-hotspot-container {
  position: absolute;
  width: 40px;
  height: 40px;
  cursor: pointer; }

.map-hotspot-container.active {
  width: 56px;
  height: 56px;
  animation: none;
  -webkit-animation: none; }

.map-hotspot-container .map-hotspot {
  width: 40px;
  height: 40px;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  animation: pulsateBg 1s ease-out;
  -webkit-animation: pulsateBg 1s ease-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  z-index: -1; }

.map-hotspot-container.active .map-hotspot {
  width: 56px;
  height: 56px;
  animation: none;
  -webkit-animation: none; }

.map-hotspot-container .circle {
  display: none;
  position: absolute;
  z-index: 1001; }

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(1, 1); }
  50% {
    -webkit-transform: scale(2.5, 2.5); }
  100% {
    -webkit-transform: scale(1, 1); } }

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.7, 0.7); }
  50% {
    -webkit-transform: scale(1, 1); }
  100% {
    -webkit-transform: scale(0.7, 0.7); } }

@-webkit-keyframes pulsateBg {
  0% {
    -webkit-transform: scale(1, 1); }
  50% {
    -webkit-transform: scale(1.1, 1.1); }
  100% {
    -webkit-transform: scale(1, 1); } }

@keyframes pulsateBg {
  0% {
    -webkit-transform: scale(0.95, 0.95); }
  50% {
    -webkit-transform: scale(1, 1); }
  100% {
    -webkit-transform: scale(0.95, 0.95); } }

.map-hotspot-container.active .circle {
  display: block;
  width: 22px;
  height: 22px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  border-radius: 50%;
  animation: pulsate 1s ease-out;
  -webkit-animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

.map-hotspot-container .ringring {
  border: 3px solid #fff;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  height: 22px;
  width: 22px;
  position: absolute;
  z-index: 1001; }

.map-hotspot-container.active .ringring {
  display: none; }

@media (max-width: 768px) {
  .map-hotspot-container {
    width: 25px;
    height: 25px; }
  .map-hotspot-container.active {
    width: 35px;
    height: 35px; }
  .map-hotspot-container .map-hotspot {
    width: 25px;
    height: 25px; }
  .map-hotspot-container.active .map-hotspot {
    width: 35px;
    height: 35px; }
  .map-hotspot-container.active .circle {
    width: 15px;
    height: 15px; }
  .map-hotspot-container .ringring {
    border-radius: 17px;
    -webkit-border-radius: 17px;
    height: 17px;
    width: 17px; } }

/* #living-hotspot.map-hotspot,
#sala-hotspot.map-hotspot {
  top: 193px;
  left: 63px;
}

#dining-hotspot.map-hotspot,
#comedor-hotspot.map-hotspot {
  top: 95px;
  left: 110px;
}

#kitchen-hotspot.map-hotspot,
#cocina-hotspot.map-hotspot {
  top: 17px;
  left: 58px;
}

#laundryroom-hotspot.map-hotspot,
#lavanderia-hotspot.map-hotspot {
  top: -11px;
  left: 62px;
}

#bedroom1-hotspot.map-hotspot,
#recamara1-hotspot.map-hotspot {
  top: 140px;
  left: 117px;
}

#bedroom2-hotspot.map-hotspot,
#recamara2-hotspot.map-hotspot {
  top: -14px;
  left: 114px;
}

#bathroom-hotspot.map-hotspot,
#baño-hotspot.map-hotspot {
  top: 35px;
  left: 140px;
}

#laundryroom-hotspot.map-hotspot,
#lavandería-hotspot.map-hotspot {
  top: -82px;
  left: 55px;
}

.map-mobile-container #living-hotspot.map-hotspot,
.map-mobile-container #sala-hotspot.map-hotspot {
  top: 110px;
  left: -37px;
}

.map-mobile-container #dining-hotspot.map-hotspot,
.map-mobile-container #comedor-hotspot.map-hotspot {
  top: 20px;
  left: 13px;
}

.map-mobile-container #kitchen-hotspot.map-hotspot,
.map-mobile-container #cocina-hotspot.map-hotspot {
  top: -63px;
  left: -43px;
}

.map-mobile-container #laundryroom-hotspot.map-hotspot,
.map-mobile-container #lavanderia-hotspot.map-hotspot {
  top: -112px;
  left: -35px;
}

.map-mobile-container #bedroom1-hotspot.map-hotspot,
.map-mobile-container #recamara1-hotspot.map-hotspot {
  top: -74px;
  left: 22px;
}

.map-mobile-container #bedroom2-hotspot.map-hotspot,
.map-mobile-container #recamara2-hotspot.map-hotspot {
  top: 65px;
  left: 22px;
}

.map-mobile-container #bathroom-hotspot.map-hotspot,
.map-mobile-container #baño-hotspot.map-hotspot {
  top: -35px;
  left: 40px;
}

.map-mobile-container #laundryroom-hotspot.map-hotspot,
.map-mobile-container #lavandería-hotspot.map-hotspot {
  top: -150px;
  left: -45px;
} */
.mobile-minimap-container {
  position: relative;
  width: 100%;
  margin: 20px 0; }

.indicator-container {
  position: absolute;
  top: 20px;
  right: 0;
  left: 0;
  margin: 0 auto;
  height: 27px;
  border-radius: 24px;
  text-transform: capitalize;
  z-index: 1000;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
  transition: all 0.3s ease-in-out;
  padding: 0px 5px;
  justify-content: center; }
  .indicator-container .logo {
    display: none; }
  .indicator-container .layout-decoration-container {
    text-align: center; }
    .indicator-container .layout-decoration-container .layout-name {
      height: 12px;
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #ffffff; }
    .indicator-container .layout-decoration-container .decoration-style {
      padding-left: 5px;
      height: 12px;
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff; }
  .indicator-container.hide .indicator-container {
    opacity: 0; }

@media (min-width: 768px) {
  .indicator-container {
    top: auto;
    bottom: 20px;
    justify-content: flex-start; }
    .indicator-container .logo {
      display: block;
      width: 19.69px;
      height: 19.69px;
      margin-right: 8px; }
    .indicator-container .layout-decoration-container .layout-name {
      font-size: 16px;
      line-height: 0.75; }
    .indicator-container .layout-decoration-container .decoration-style {
      font-size: 16px;
      font-weight: 300;
      line-height: 0.75; } }

.modal .alert-modal.modal-dialog {
  background: transparent;
}

.alert-modal .modal-content {
  width: 323px;
  height: 336px;
  margin: 0 auto;
  background-color: #fafafa;
  border-radius: 20px;
  box-shadow: 0 4px 6px 0 rgba(138, 138, 138, 0.5);
}

.modal-backdrop {
  background-color: rgba(74, 74, 74, 0.65);
}

.exclamation-container {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 4px solid #c8270c;
  font-size: 2.1rem;
  text-align: center;
  color: #c8270c;
  margin-bottom: 20px;
}

.error-message {
  width: 236px;
  height: 32px;
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.52;
  letter-spacing: normal;
  text-align: center;
  color: #d0021b;
}

.message {
  width: 263px;
  height: 74px;
  font-size: 21px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.52;
  letter-spacing: normal;
  text-align: center;
  color: #6c6c6c;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.feature-container {
  position: fixed;
  width: 200px;
  height: 40px;
  z-index: 1000;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.feature-container.hide {
  opacity: 0;
}

.feature-container.autoplay {
  bottom: 16px;
  left: 16px;
}

.feature-container.cardboard {
  bottom: calc(3% + 47px);
  left: 16px;
}

.feature-container .autoplay-icon,
.feature-container .cardboard-icon {
  width: 30px;
  height: 30px;
  object-fit: contain;
  z-index: 1000;
}

.feature-container .feature-tooltip {
  opacity: 0;
  border-radius: 20px;
  height: 30px;
  width: 130px;
  z-index: 1000;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.3);
  margin-left: 10px;
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.feature-container .feature-tooltip.active {
  -webkit-animation: fade-in 1s ease-in-out forwards;
          animation: fade-in 1s ease-in-out forwards;
}

.feature-container .feature-tooltip.inactive {
  -webkit-animation: fade-out 2s ease-in-out forwards;
          animation: fade-out 2s ease-in-out forwards;
}

.cardboard-tooltip {
  opacity: 0;
  position: fixed;
  z-index: 1000;
  bottom: calc(3% + 27px);
  left: 56px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;
  width: 230px;
  height: 90px;
  border-radius: 20px;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.3);
}

.cardboard-tooltip span {
  padding: 20px;
}

.cardboard-tooltip.active {
  -webkit-animation: fade-in 1s ease-in-out forwards;
          animation: fade-in 1s ease-in-out forwards;
}

.cardboard-tooltip.inactive {
  -webkit-animation: fade-out 5s ease-in-out forwards;
          animation: fade-out 5s ease-in-out forwards;
}

.autoplay-icon-tablet {
  position: fixed;
  width: 30px;
  height: 30px;
  object-fit: contain;
  z-index: 1000;
  bottom: 16px;
  left: 16px;
}
#dropdown-basic-button {
  position: absolute;
  top: 120px;
  right: 10px;
  z-index: 1000;
  background-color: #ed6b6a;
  border-color: #ed6b6a;
}

.btn-stop-tour {
  position: absolute;
  top: 170px;
  right: 10px;
  z-index: 1000;
}

.shopping-icon {
  position: fixed;
  width: 40px;
  height: 40px;
  object-fit: contain;
  z-index: 1007;
  top: 5%;
  right: 15px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  cursor: pointer;
}

.shop-icon-tablet {
  position: fixed;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
  object-fit: contain;
  z-index: 1000;
  bottom: 3%;
  left: 22px;
  cursor: pointer;
}

.shop-icon-tablet.active {
  top: 40%;
}

@media (orientation: landscape) {
  .shop-icon-tablet.active {
    top: 40%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .shop-icon-tablet.active {
    top: 27%;
  }
}

@media (orientation: portrait) {
  .shop-icon-tablet.active {
    top: 55%;
  }
}

.shop-icon-desktop {
  position: fixed;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
  object-fit: contain;
  z-index: 1000;
  bottom: 5%;
  left: 22px;
  cursor: pointer;
}

.shop-icon-desktop.active {
  top: 5%;
}

.shopping-car-menu {
  width: 95%;
  background: #fafafa;
  z-index: 1020;
  bottom: 0;
  left: 0;
  margin: 0 auto;
}

.shopping-car-menu .title {
  font-family: 'Roboto';
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #4a4a4a;
  padding-left: 20px;
  padding-top: 30px;
}

.shopping-car-menu .items-container {
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.2s;
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
  will-change: transform;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
}

.shopping-car-menu .items-container::-webkit-scrollbar {
  display: none;
}

.shopping-car-menu .items-container.active {
  background: rgba(255, 255, 255, 0.3);
  cursor: grabbing;
  cursor: -webkit-grabbing;
  /*transform: scale(1);*/
}

.btn.login-button.btn-primary {
  background-color: #000000;
  border-color: #000000;
  border-radius: 20px;
  width: 70%;
}

.modal .modal-body,
.modal .modal-content,
.modal .modal-dialog {
  width: 323px;
  height: 275px;
  border-radius: 20px;
  margin: 5px auto;
  box-shadow: 0 4px 6px 0 rgba(138, 138, 138, 0.5);
  background-color: #fafafa;
}

.modal-dialog-centered {
  min-height: 275px;
}

.modal-dialog-centered:before {
  height: 275px;
}

.scroll-items {
  width: 160%;
}

.shopping-car-menu .shopping-car-item {
  width: 100%;
  height: 240px;
  position: relative;
  display: inline-block;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(138, 138, 138, 0.39),
    0 1px 2px 0 rgba(138, 138, 138, 0.5);
  background-color: #ffffff;
  margin-bottom: 16px;
}

@media screen and (orientation: landscape) {
  .shopping-car-menu .shopping-car-item {
    width: 49%;
  }

  .shopping-car-menu .items-container {
    justify-content: space-between;
  }
}

.shopping-car-menu .shopping-car-item .item {
  width: 100%;
  height: 100%;
}

.shopping-car-menu .shopping-car-item.desktop {
  display: inline-block;
}

.shopping-car-menu .shopping-car-item.mobile {
  display: inline-block;
}

.shopping-car-menu .shopping-car-item .fav-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1000;
}

.shopping-car-menu .shopping-car-item .image {
  width: 188px;
  height: 121px;
  object-fit: contain;
}

.shopping-car-menu .shopping-car-item .image img {
  width: 100%;
  height: 100%;
}
.shopping-car-menu .shopping-car-item .name {
  width: 100%;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  padding-top: 16px;
  padding-left: 16px;
}

.shopping-car-menu .shopping-car-item .retailer {
  width: 100%;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.14;
  letter-spacing: normal;
  color: #6c6c6c;
  padding-left: 16px;
  padding-bottom: 11px;
  padding-top: 5px;
}

.shopping-car-menu .shopping-car-item .colors {
  width: 100%;
  padding-left: 11px;
  height: 110px;
  overflow: auto;
}

.shopping-car-menu .shopping-car-item .colors .color-circle-container {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 5px 0;
}

.shopping-car-menu .shopping-car-item .colors .color-circle-container.active,
.shopping-car-menu .shopping-car-item .colors .color-circle-container:hover {
  border: solid 2px #000000;
}

.shopping-car-item .footer {
  width: 100%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 70px;
  background-color: #252525;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
}

.shopping-car-item .footer .price {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #ffffff;
  padding-left: 16px;
}

.shopping-car-item .footer .currency {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #ffffff;
}

@media (max-width: 576px) {
  .shop-icon-desktop {
    display: none;
  }

  .shopping-car-menu .title {
    display: none;
  }

  /* .shopping-car-menu .items-container {
    margin-top: 10%;
  } */

  .shopping-car-menu .shopping-car-item {
    width: 100%;
    height: 240px;
    min-height: 240px;
    position: relative;
    display: inline-block;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(138, 138, 138, 0.39),
      0 1px 2px 0 rgba(138, 138, 138, 0.5);
    background-color: #ffffff;
  }

  .shopping-car-menu .shopping-car-item.desktop {
    display: none;
  }

  /* .shopping-car-menu .shopping-car-item.mobile {
    display: inline-block;
  } */

  .shopping-car-menu .shopping-car-item .image {
    width: 188px;
    height: 121px;
    object-fit: contain;
    margin-left: 16px;
  }
}

@media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) {
  .shopping-car-menu .shopping-car-item {
    width: 300px;
    height: 249px;
    min-height: 249px;
    min-width: 300px;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(138, 138, 138, 0.5);
    background-color: #ffffff;
    position: relative;
    display: inline-block;
    margin: 2em 1em;
  }

  /* .shopping-car-menu .items-container {
    margin-top: 22%;
  } */

  .shopping-car-menu .title {
    display: none;
  }
  .shopping-car-menu .shopping-car-item.desktop {
    display: none;
  }
  .shopping-car-menu .shopping-car-item.mobile {
    display: inline-block;
  }

  .shopping-car-menu .shopping-car-item .image {
    width: 188px;
    height: 121px;
    object-fit: contain;
  }

  .shopping-car-item .footer .price {
    padding-left: 20px;
  }
}

@media screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .shopping-car-menu .shopping-car-item {
    width: 385px;
    height: 249px;
    min-height: 249px;
    min-width: 385px;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(138, 138, 138, 0.5);
    background-color: #ffffff;
    position: relative;
    display: inline-block;
    margin: 2em 1em;
  }
  .shopping-car-menu .shopping-car-item.desktop {
    display: none;
  }
  .shopping-car-menu .shopping-car-item.mobile {
    display: inline-block;
  }

  .shopping-car-menu .shopping-car-item .image {
    width: 188px;
    height: 121px;
    object-fit: contain;
  }

  .shopping-car-item .footer .price {
    padding-left: 20px;
  }
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.test {
  position: absolute;
  z-index: 9999;
  top: 50px;
  right: 250px;
}

#viewer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: #fafafa;
}

.red {
  color: red;
  font-weight: bold;
}
.blue {
  color: blue;
  font-weight: bold;
}

.hotspot {
  margin: 25px;
  width: 20px;
  position: absolute;
}

.react-joyride__beacon {
  outline: none !important;
}

@media screen and (device-width: 568px) and (device-height: 320px) and (-webkit-device-pixel-ratio: 2) {
  div.viewer-container {
    display: block !important;
  }
}

.react-joyride__beacon span:first-child {
  background-color: #ed6b6a !important;
}

.react-joyride__beacon span {
  border: 2px solid #ed6b6a !important;
}

.tooltip {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  background: #4a4a4a;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  line-height: 1.5;
  letter-spacing: normal;
  border-radius: 10%;
  color: rgba(255, 255, 255);
  -webkit-transform: translate3d(-50%, -100%, 0);
          transform: translate3d(-50%, -100%, 0);
  opacity: 0;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
}

.tooltip.is-active {
  opacity: 0.75;
  -webkit-transform: translate3d(-50%, calc(-100% - 80px), 0);
          transform: translate3d(-50%, calc(-100% - 80px), 0);
}

