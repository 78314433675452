.indicator-container {
  position: absolute;
  top: 20px;
  right: 0;
  left: 0;
  margin: 0 auto;
  height: 27px;
  border-radius: 24px;
  text-transform: capitalize;
  z-index: 1000;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
  transition: all 0.3s ease-in-out;
  padding: 0px 5px;
  justify-content: center;
  & .logo {
    display: none;
  }
  & .layout-decoration-container {
    text-align: center;
    & .layout-name {
      height: 12px;
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #ffffff;
    }
    & .decoration-style {
      padding-left: 5px;
      height: 12px;
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }
  }
  &.hide {
    & .indicator-container {
      opacity: 0;
    }
  }
}

@media (min-width: 768px) {
  .indicator-container {
    top: auto;
    bottom: 20px;
    justify-content: flex-start;
    & .logo {
      display: block;
      width: 19.69px;
      height: 19.69px;
      margin-right: 8px;
    }
    & .layout-decoration-container {
      & .layout-name {
        font-size: 16px;
        line-height: 0.75;
      }
      & .decoration-style {
        font-size: 16px;
        font-weight: 300;
        line-height: 0.75;
      }
    }
  }
}
