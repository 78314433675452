.modal .alert-modal.modal-dialog {
  background: transparent;
}

.alert-modal .modal-content {
  width: 323px;
  height: 336px;
  margin: 0 auto;
  background-color: #fafafa;
  border-radius: 20px;
  box-shadow: 0 4px 6px 0 rgba(138, 138, 138, 0.5);
}

.modal-backdrop {
  background-color: rgba(74, 74, 74, 0.65);
}

.exclamation-container {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 4px solid #c8270c;
  font-size: 2.1rem;
  text-align: center;
  color: #c8270c;
  margin-bottom: 20px;
}

.error-message {
  width: 236px;
  height: 32px;
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.52;
  letter-spacing: normal;
  text-align: center;
  color: #d0021b;
}

.message {
  width: 263px;
  height: 74px;
  font-size: 21px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.52;
  letter-spacing: normal;
  text-align: center;
  color: #6c6c6c;
}
